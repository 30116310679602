import { render, staticRenderFns } from "./viewLog2.vue?vue&type=template&id=ec84993c&scoped=true"
import script from "./viewLog2.vue?vue&type=script&lang=js"
export * from "./viewLog2.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec84993c",
  null
  
)

export default component.exports